.leftrail-timeline-icon {
  border-radius: 50%;
  background-color: white;
  position: relative;
  float: left;
  left: -38px;
  text-align: center;
}

.leftrail-rounded {
  border-radius: 20px;
}

.leftrail-rounded-right {
  border-radius: 0 20px 20px 0;
}

/* overriding bootstrap class */
.progress-bar {
  height: inherit;
  background-color: white !important;
}

/* overriding bootstrap class */
.progress {
  height: 4px !important;
  background-color: #333b3f !important;
}

@media (max-width: 576px) {
  .leftrail-rounded-right {
    border-radius: 0 0 20px 20px;
  }
}
